import { STATUS_PENDING } from "@/constants/properties";

export default {
  type: "properties",
  name: "",
  status: STATUS_PENDING,
  acquisition_number: "",
  acquisition_method: "",
  property_number: "",
  bid_starting_amount: "",
  is_starting_amount_automatic: true,
  sold_at: null,
  sold_amount: null,
  mrc_fees: null,
  origin: null,
  reference_css: null,
  sale_confirmed_at: null,
  owed_taxes_school_board: null,
  owed_taxes_municipality: null,
  registration_number: null,
  excerpt: "",
  transaction_date: new Date(),
  batch_numbers: [
    {
      value: null,
    },
  ],
  transactions: [],
  taxable: false,
  cancel_reason: null,
  transaction_excerpt: null,
  customer: null,
  designation: "",
  property_type: "",
  country: "Canada",
  state: "Quebec",
  city: "",
  zipcode: "",
  address: "",
  relationshipNames: ["organization", "owners", "allowedLocations", "auction", "registrationDivision"],
  organization: {
    type: "organizations",
    id: null,
  },
  auction: {
    type: "auctions",
    id: null,
  },
  registrationDivision: {
    type: "registration-divisions",
    id: null,
  },
  owners: [],
  allowedLocations: [],
};
