var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"inputClasses":"text-uppercase","label":`${_vm.$t('PROPERTIES.NAME')} (*)`},on:{"change":() => {
              _vm.onFormChanged();
            }},model:{value:(_vm.property.name),callback:function ($$v) {_vm.$set(_vm.property, "name", $$v)},expression:"property.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}})],1),_c('div',{staticClass:"form-wrapper full"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.property.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.property.id},on:{"organizationChanged":(organizationId, organization) => {
                _vm.property.organization.id = organizationId;
                _vm.property.allowedLocations = [];
                _vm.onFormChanged();
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1),_c('div',{staticClass:"form-wrapper full"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.property.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.property.organization.id},on:{"locationsChanged":(locations) => {
                _vm.property.allowedLocations = locations;
                _vm.onFormChanged();
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.allowedLocations}})],1),_c('div',{staticClass:"form-wrapper full"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CONTACTS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.OWNERS')}`,"placeholder":_vm.$t('COMMON.OWNERS')}},[_c('contacts-selector',{attrs:{"placeholder":_vm.$t('COMMON.OWNERS'),"contacts":_vm.property.owners,"filterable":true,"showAll":false,"multiple":true,"filterOrganization":_vm.property.organization.id},on:{"contactsChanged":(contacts) => {
                _vm.property.owners = contacts;
                _vm.onFormChanged();
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.owners}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.STATUS')} (*)`,"placeholder":_vm.$t('COMMON.STATUS')}},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"clearable":true,"placeholder":_vm.$t('COMMON.STATUS')},on:{"change":(value) => {
                if (value !== _vm.STATUS_CANCEL) {
                  _vm.property.cancel_reason = null;
                }
                _vm.onFormChanged();
              }},model:{value:(_vm.property.status),callback:function ($$v) {_vm.$set(_vm.property, "status", $$v)},expression:"property.status"}},[_vm._l((_vm.PROPERTY_STATUS),function(item,key){return [(_vm.limitedStatus.includes(item))?_c('el-option',{key:key,staticClass:"select-primary",attrs:{"label":_vm.$t(`PROPERTIES.PROPERTIES_STATUS_${item}`),"value":item}}):_vm._e()]})],2)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.status}})],1),(_vm.property.status === _vm.STATUS_CANCEL)?_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('PROPERTIES.CANCEL_REASON')} (*)`,"placeholder":_vm.$t('PROPERTIES.CANCEL_REASON')}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.property.cancel_reason),expression:"property.cancel_reason"}],staticClass:"form-control",attrs:{"placeholder":_vm.$t('PROPERTIES.CANCEL_REASON'),"rows":"3"},domProps:{"value":(_vm.property.cancel_reason)},on:{"change":_vm.onFormChanged,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.property, "cancel_reason", $event.target.value)}}})]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.cancel_reason}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{staticStyle:{"text-transform":"uppercase"},attrs:{"label":`${_vm.$t('COMMON.ADDRESS')} (*)`,"inputClasses":"text-uppercase"},on:{"change":() => {
              _vm.onFormChanged();
            }},model:{value:(_vm.property.address),callback:function ($$v) {_vm.$set(_vm.property, "address", $$v)},expression:"property.address"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.address}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"inputClasses":"text-uppercase","label":`${_vm.$t('COMMON.CITY')} (*)`},on:{"change":() => {
              _vm.onFormChanged();
            }},model:{value:(_vm.property.city),callback:function ($$v) {_vm.$set(_vm.property, "city", $$v)},expression:"property.city"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.city}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"inputClasses":"text-uppercase","label":`${_vm.$t('COMMON.ZIPCODE')}`},on:{"change":() => {
              _vm.onFormChanged();
            }},model:{value:(_vm.property.zipcode),callback:function ($$v) {_vm.$set(_vm.property, "zipcode", $$v)},expression:"property.zipcode"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.zipcode}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.STATE')} (*)`,"placeholder":_vm.$t('COMMON.STATE')}},[_c('state-selector',{attrs:{"inputClasses":"text-uppercase","country":_vm.property.country,"state":_vm.property.state,"filterable":true,"showAll":false},on:{"stateChanged":(state) => {
                _vm.property.state = state;
                _vm.onFormChanged();
              }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.state}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.COUNTRY')} (*)`,"placeholder":_vm.$t('COMMON.COUNTRY')}},[_c('country-selector',{attrs:{"inputClasses":"text-uppercase","country":_vm.property.country,"filterable":true,"showAll":false},on:{"countryChanged":(country) => {
                _vm.property.country = country;
                _vm.onFormChanged();
              }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.country}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NOTE')}`,"placeholder":_vm.$t('COMMON.NOTE')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.property.excerpt),callback:function ($$v) {_vm.$set(_vm.property, "excerpt", $$v)},expression:"property.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.property.id ? _vm.$t("PROPERTIES.EDIT_PROPERTY") : _vm.$t("PROPERTIES.ADD_PROPERTY"))+" ")])],1)]),_c('modal',{attrs:{"show":_vm.showModal,"modal-classes":"modal-secondary","size":"sm"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h2',{attrs:{"id":""}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CONFIRMATION"))+" ")])]),_c('div',{},[_c('div',{staticClass:"text-center mt--4"},[_vm._v(" "+_vm._s(_vm.$t("PROPERTIES.CHANGE_READY_STATUS"))+" ")]),_c('div',{staticClass:"text-center"},[_c('strong',{staticClass:"text-lowercase"},[_vm._v(" "+_vm._s(_vm.$t("PROPERTIES.CONFIRMATION_QUESTION", { status: _vm.$t(`PROPERTIES.PROPERTIES_STATUS_${_vm.property.status}`), }))+" ")])])]),_c('template',{slot:"footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.loading},on:{"click":_vm.continueFormSubmitProcess}},[(_vm.loading)?_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("COMMON.CONTINUE"))+" ")]),_c('button',{staticClass:"btn btn-link ml-auto",attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.toggleModalState}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CANCEL"))+" ")])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }